<template>
  <div>
    <b-card text-variant="center" class="card card-congratulations">
        <!-- images -->
        <b-img :src="require('@/assets/images/elements/decore-left.png')" class="congratulations-img-left" />
        <b-img :src="require('@/assets/images/elements/decore-right.png')" class="congratulations-img-right" />
        <!--/ images -->

        <b-avatar variant="primary" size="70" class="shadow mb-2">
            <feather-icon size="28" icon="AwardIcon" />
        </b-avatar>
        <h1 class="mb-1 mt-50 text-white">
            Congratulations {{ name }}
        </h1>
        <b-card-text class="m-auto w-75">
            You have done <strong>{{Number((Math.floor(Math.random() * 100))).toLocaleString(undefined, {minimumFractionDigits: 0})}}%</strong> more SALES today.🤑
        </b-card-text>
    </b-card>
    <b-card text-variant="center" class="card" style="    height: 500px;">
            <template #header>
                <h1 class="mb-0">Welcome to the Google Sheets Free Zone🙅‍♂️🖖🏼</h1>
            </template>
        <lottie-animation path="home.json"/>
    </b-card>
    <b-row>
        <b-col cols="6" class="mb-2">
            <b-card style="height: 250px;     text-align: center;">
              <template #header>
                <h1 class="mb-0">Clearspace Mission🚀</h1>
              </template>

                <b-card-text style="font-size: large;">Make better spaces available to more people!</b-card-text>
            </b-card>
        </b-col>

        <b-col cols="6" class="mb-2" >
            <b-card style="height: 250px;      text-align: center;">
                <template #header>
                  <h1 class="mb-0">Clearspace Values 🔒</h1>
                </template>
                <b-card-text style="font-size: large;">Integrity first.</b-card-text>
                <b-card-text style="font-size: large;">Grit.</b-card-text>
                <b-card-text style="font-size: large;">Ownership.</b-card-text>
                <b-card-text style="font-size: large;">Continuous Improvement.</b-card-text>
                <b-card-text style="font-size: large;">Treat People Well.</b-card-text>
            </b-card>
        </b-col>
    </b-row>
  </div>
</template>

<script>
import {
    BCard,
    BCardText,
    BLink,
    BRow,
    BImg,
    BCol,
    BAvatar
} from 'bootstrap-vue'
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"

export default {
    components: {
        BCard,
        BCardText,
        BLink,
        BImg,
        BRow,
        BCol,
        BAvatar,
        LottieAnimation
    },
    computed: {
        name() {
            return localStorage.getItem('name')
        }
    },
}
</script>

<style scoped>
.card-header{
  align-self: center !important;
}
</style>
